import React, { useState } from 'react';
import logo from '../logo.svg';

const Nav = () => {
  const [opened, setOpened] = useState(true);

  return (
    <header className='w-100'>
      <div className='container'>
        <header className='flex jcsb aic h-10 py-5'>
          <h1 id='logo'>
            <a href='/' aria-label='Logotype'>
              <img
                src={logo}
                width={187}
                height={32}
                alt='emagweb'
                style={{ transform: 'translateY(-5px)' }}
              />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={() => setOpened(!opened)}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a
              href='#crafted'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Crafted
            </a>
            <a
              href='#price'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Price
            </a>
            <a
              href='#faq'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              F.A.Q
            </a>
            <a
              href='#contact'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Contact
            </a>
          </div>
          <a href='/settings/plans/' className='button top'>
            Login
          </a>
        </header>
      </div>
    </header>
  );
};

export { Nav };

import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';

const FAQ = () => {
  return (
    <section id='faq'>
      <div className='container'>
        <div className='s-100'></div>
        <div className='m-40'></div>
        <div className='w-80 m-100 ma'>
          <h3 className='title tc'>Frequently Asked Questions</h3>
          <div className='s-30'></div>
          <p className='title-sub tc'>
            Here you'll find common questions about our service along with their
            answers.
          </p>
        </div>
        <div className='w-80 m-100 ma'>
          <MDBContainer className='mt-5'>
            <MDBAccordion alwaysOpen initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle='What is the duration of the trial period?'
              >
                Our trial period lasts for 7 days, allowing you to explore our
                design services. While this period is not free, it provides an
                opportunity to receive a refund if you decide to discontinue our
                services within this time frame.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle='How does the 7-day money-back guarantee work?'
              >
                We offer a 7-day money-back guarantee to prioritize your
                satisfaction. If, within the trial period, you choose to
                discontinue our services, we provide a full refund or partial
                reimbursement, depending on your preferences.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle='What is included in the trial plan?'
              >
                The trial plan grants you access to basic features and
                functionalities of our services, including UI/UX design, package
                design, presentations, web design, banners, flyers, app design,
                logos, product design, email, print design, and seamless
                communication through Slack.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={4}
                headerTitle='Can I assess the efficiency of collaboration during the trial?'
              >
                Certainly. In the first 5 days, you can gauge the efficiency and
                seamlessness of collaborating with our team. This period is
                designed to showcase our commitment to delivering results.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={5}
                headerTitle='What happens after the 7-day trial?'
              >
                Following the completion of the 7-day trial, you have the option
                to choose one of our paid monthly plans, each offering
                additional features and benefits based on your specific needs.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={6}
                headerTitle='What are the available monthly plans?'
              >
                We offer three monthly plans:
                <ul>
                  <li>Standard: €700/mo.</li>
                  <li>Pro: €2000/mo.</li>
                  <li>Enterprise: Custom</li>
                </ul>
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={7}
                headerTitle='How can I customize an Enterprise plan?'
              >
                For a customized Enterprise plan, tailored specifically to your
                needs, you can schedule a Zoom meeting using Calendly. During
                the meeting, we will discuss your requirements and determine the
                features and pricing that best suit your project.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={8}
                headerTitle='Is there a money-back guarantee for the paid monthly plans?'
              >
                While the 7-day money-back guarantee specifically applies to the
                trial period, we are committed to ensuring your satisfaction
                with our paid monthly plans. Feel free to reach out to us
                through Slack if you have any concerns or questions.
              </MDBAccordionItem>
            </MDBAccordion>
          </MDBContainer>
        </div>
        <div className='s-100'></div>
        <div className='s-80'></div>
      </div>
    </section>
  );
};
export { FAQ };
